<template>
  <div id="misc">
    <div class="page-title text-center px-5">
      <h2
        class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center"
      >
        <span class="me-2">Page Not Found</span>
        <v-icon color="warning">
          mdi-alert
        </v-icon>
      </h2>
      <p class="text-sm">
        we couldn't find the page you are looking for
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <v-btn color="primary" to="/" class="mb-4">
        Back to home
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/styles/misc.scss";
</style>
